/*
 * List of effect names that can be used
 * ------------------------------------------------------------
 * Use  .js-animate-single-*name-of-effect* for single elements
 * or use  .js-animate-*name-of-effect* for multiple element
 * ------------------------------------------------------------
 * - fadeIn
 * - fadeInLeft
 * - fadeInRight
 * - fadeInUp
 * - fadeInDown
 */

$elements: 12;
@for $i from 0 to $elements {
  .js-animate:nth-child(#{$i + 1}) {
    -webkit-animation-delay: 300ms * ($i + 1);
    animation-delay: 300ms * ($i + 1);
  }
}

.js-animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;

}

/******************************************************************************************
* Fade In *
******************************************************************************************/
.js-animate-single-fadeIn{
  opacity:0;

  &.js-animate {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
}
@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;
    display:block;}
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;
    display:block;}
}

.js-animate-single-fadeInRight,
.js-animate-single-fadeInRightShort,
.js-animate-single-fadeInLeft,
.js-animate-single-fadeInLeftShort,
.js-animate-single-fadeInUp,
.js-animate-single-fadeInUpShort,
.js-animate-single-fadeInDownShort,
.js-animate-single-fadeInDown{
  opacity:0;
  //-webkit-transform: translateX(400px);
  //transform: translateX(400px);
}

/*************
* FadeInLeft *
*************/

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.js-animate-single-fadeInLeft {
  opacity: 0;
  -webkit-transform: translateX(-400px);
  transform: translateX(-400px);

  &.js-animate {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }
}


/*************
* FadeInRight *
*************/

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.js-animate-single-fadeInRight {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px);

  &.js-animate {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
}

/*************
* FadeInUp *
*************/

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-animate-single-fadeInUp{
  opacity: 0;
  -webkit-transform: translateY(400px);
  transform: translateY(400px);

  &.js-animate {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
}

/*************
* FadeInDown *
*************/

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-400px);
  }
  50%{
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-animate-single-fadeInDown {
  opacity: 0;
  -webkit-transform: translateY(-400px);
  transform: translateY(-400px);

  &.js-animate {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
}

/*************
* FadeInLeftShort *
*************/

@-webkit-keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.js-animate-single-fadeInLeftShort {
  opacity: 0;
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);

  &.js-animate {
    -webkit-animation-name: fadeInLeftShort;
    animation-name: fadeInLeftShort;
  }
}

/*************
* FadeInRightShort *
*************/

@-webkit-keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes fadeInRightShort {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.js-animate-single-fadeInRightShort {
  opacity: 0;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);

  &.js-animate {
    -webkit-animation-name: fadeInRightShort;
    animation-name: fadeInRightShort;
  }
}

/*************
* FadeInUpShort *
*************/

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-animate-single-fadeInUpShort{
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);

  &.js-animate {
    -webkit-animation-name: fadeInUpShort;
    animation-name: fadeInUpShort;
  }
}

/*************
* FadeInDownShort *
*************/

@-webkit-keyframes fadeInDownShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInDownShort {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-animate-single-fadeInDownShort {
  opacity: 0;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);

  &.js-animate {
    -webkit-animation-name: fadeInDownShort;
    animation-name: fadeInDownShort;
  }
}