// Colors
$link-color-default:		#000;
$link-color-hover:			#333;
$link-color-active:			#000;
$link-color-visited:		#000;

// Decoration
$link-decoration-default:	underline;
$link-decoration-hover:		none;
$link-decoration-active: 	underline;
$link-decoration-visited: 	underline;
