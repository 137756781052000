// Imports
@import "general";

@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,400i,700,700i&display=swap');

@font-face {
    font-family: 'accordalternatebold';
    src: url('../fonts/soneri_type_-_accordalternate-bold-webfont.woff2') format('woff2'),
    url('../fonts/soneri_type_-_accordalternate-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'accordalternateregular';
    src: url('../fonts/soneri_type_-_accordalternate-webfont.woff2') format('woff2'),
    url('../fonts/soneri_type_-_accordalternate-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body {
    height: 100%;
}

body {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: $base-color1;
    font-size: 18px;
    line-height: 26px;

    @include breakpoint(tablet-landscape) {
        font-size: 16px;
        line-height: 22px;
    }
}

h1, h2, h3, h4 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    color: $base-color1;
}

a {
    color: #007eb4;

    &:visited {
        color: #007eb4;
    }
}

.container {
    position: relative;

    @include breakpoint(tablet) {
        width: 100%;
    }
}

.custom-container {
    position: relative;
    width: -webkit-calc(100% - 320px);
    width: calc(100% - 320px);
    max-width: 1400px;
    height: 100%;
    margin: auto;

    @include breakpoint(laptop) {
        width: 100%;
        padding: 0 15px;
    }

    @include breakpoint(tablet-landscape) {
        width: 100%;
        padding: 0 15px;
    }
}

.mt-50 {
    margin-top: 50px;
}

.search-active {
    #header {
        height: 210px;

        .search-form {
            height: 65px;
        }

        .navbar {
            top: 110px;
        }
    }
}

.menu-active {
    #header {
        height: 100%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;

        .navbar {
            height: 100%;

            .avri {
                svg {
                    fill: #FFF !important;
                }

                &:before {
                    border-left: 1px dotted #FFF;
                }

                &:after {
                    border-left: 1px dotted #FFF;
                }
            }

            nav {
                visibility: visible;
                height: -webkit-calc(100% + 45px);
                height: calc(100% + 45px);

                ul {
                    visibility: visible;
                    opacity: 1;
                    -webkit-transition: all 0.3s ease 0.3s;
                    transition: all 0.3s ease 0.3s;
                }
            }
        }
    }
}

#header {
    position: absolute;
    width: 100%;
    height: 145px;
    top: 0px;
    left: 0px;
    z-index: 9;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    @include breakpoint(laptop) {
        height: 135px;
    }

    @include breakpoint(mobile) {
        height: 115px;
    }

    &.fixed {

        .search-form {
            position: fixed;
            top: 0px;
        }

        .navbar {
            position: fixed;
            margin-top: -45px;

            .logo {
                -webkit-transform: scale(0.6);
                transform: scale(0.6);
                margin-top: -50px;

                @include breakpoint(mobile) {
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    margin-top: 0px;
                }
            }
        }
    }

    .meta {
        position: absolute;
        width: 100%;
        height: 45px;
        background: $base-color1;
        top: 0px;
        left: 0px;

        nav {
            ul {
                float: left;
                list-style: none;
                padding: 10px 0px 0px 0px;
                margin: 0px;

                li {
                    float: left;
                    position: relative;
                    padding-right: 10px;
                    margin-right: 10px;

                    a {
                        float: left;
                        color: #FFF;
                        text-decoration: none;
                        font-size: 18px;
                        -webkit-transition: opacity 0.2s ease;
                        transition: opacity 0.2s ease;

                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    &:not(:last-child) {

                        &:after {
                            content: "";
                            position: absolute;
                            width: 1px;
                            height: 12px;
                            background: #FFF;
                            top: 7px;
                            right: 0px;
                        }
                    }
                }
            }
        }
    }

    .search-form {
        position: absolute;
        background: $base-color2;
        width: 100%;
        height: 0px;
        overflow: hidden;
        top: 45px;
        z-index: 33;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;

        form {
            float: left;
            width: -webkit-calc(100% + 15px);
            width: calc(100% + 15px);
            margin-top: 12px;

            input {
                float: left;
                width: -webkit-calc(100% - 138px);
                width: calc(100% - 138px);
                height: 41px;
                border: 0px;
            }

            button {
                float: left;
                width: 125px;
                height: 41px;
                margin-left: 10px;
                background: $base-color1;
                color: #FFF;
                border: 0px;
                cursor: pointer;
                font-size: 18px;
            }
        }
    }

    .navbar {
        position: absolute;
        width: 100%;
        height: 100px;
        background: #FFF;
        top: 45px;
        left: 0px;
        -webkit-box-shadow: 0 4px 2px -2px rgba(0,0,0, 0.1);
        box-shadow: 0 4px 2px -2px rgba(0,0,0, 0.1);
        -webkit-transition: top 0.3s ease;
        transition: top 0.3s ease;

        @include breakpoint(laptop) {
            height: 90px;
        }

        @include breakpoint(mobile) {
            height: 70px;
        }

        .logo {
            position: absolute;
            width: 380px;
            height: 253px;
            background: url('../img/logo.svg') center no-repeat;
            background-size: 380px 253px;
            top: 0px;
            left: 0px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;

            @include breakpoint(laptop) {
                width: 320px;
                height: 213px;
                background-size: 320px 213px;
            }

            @include breakpoint(mobile) {
                width: 140px;
                height: 94px;
                background-size: 140px 94px;
            }
        }

        .search {
            position: absolute;
            width: 50px;
            height: 50px;
            background: url('../img/search.svg') center no-repeat;
            background-size: 50px 50px;
            border: 0px;
            top: 25px;
            right: 170px;
            cursor: pointer;

            @include breakpoint(laptop) {
                top: 20px;
            }

            @include breakpoint(mobile) {
                width: 40px;
                height: 40px;
                background-size: 40px 40px;
                right: 135px;
                top: 15px;
            }

            &:after {
                content: "";
                position: absolute;
                border-left: 1px dotted #8cd4f3;
                width: 1px;
                height: 50px;
                top: 0px;
                right: -20px;

                @include breakpoint(mobile) {
                    right: -15px;
                }
            }
        }

        .avri {
            position: absolute;
            width: 50px;
            height: 50px;
            padding: 0px 10px;
            top: 25px;
            right: 10px;
            z-index: 10;

            @include breakpoint(laptop) {
                top: 20px;
            }

            @include breakpoint(mobile) {
                width: 40px;
                height: 40px;
                top: 15px;
            }

            &:before {
                content: "";
                position: absolute;
                border-left: 1px dotted #8cd4f3;
                width: 1px;
                height: 50px;
                left: -10px;

            }

            &:after {
                content: "";
                position: absolute;
                border-left: 1px dotted #8cd4f3;
                width: 1px;
                height: 50px;
                right: -10px;
            }

            svg {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 0px;
                left: 0px;
                fill: $base-color1;

                @include breakpoint(mobile) {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .hamburger {
            display: block;
            position: absolute;
            top: 0px;
            z-index: 10;
            right: 80px;
            padding: 5px;
            display: inline-block;
            cursor: pointer;
            transition-property: opacity, filter;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            font: inherit;
            color: inherit;
            text-transform: none;
            background-color: transparent;
            border: 0;
            margin: 0;
            overflow: visible;

            @include breakpoint(laptop) {
                margin-top: -5px;
            }

            @include breakpoint(mobile) {
                -webkit-transform: scale(0.7);
                transform: scale(0.7);
                right: 60px;
                margin-top: -12px;
            }

            .title {
                display: block;
                color: $base-color1;
                font-size: 18px;
                margin-top: -4px;
                text-transform: uppercase;
                font-weight: bold;

            }

            &.is-active {
                .title {
                    color: #FFF;
                }
            }
        }

        .hamburger-box {
            width: 46px;
            height: 32px;
            display: inline-block;
            position: relative;
            margin-top: 18px;
        }

        .hamburger-inner {
            display: block;
            top: 50%;
            margin-top: -2px; }
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
            width: 46px;
            height: 5px;
            background-color: $base-color1;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease; }
        .hamburger-inner::before, .hamburger-inner::after {
            content: "";
            display: block; }
        .hamburger-inner::before {
            top: -12px; }
        .hamburger-inner::after {
            bottom: -10px; }

        /*
           * Collapse
           */
        .hamburger--collapse .hamburger-inner {
            top: auto;
            bottom: 0;
            transition-duration: 0.13s;
            transition-delay: 0.13s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
        .hamburger--collapse .hamburger-inner::after {
            top: -24px;
            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
        .hamburger--collapse .hamburger-inner::before {
            transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

        .hamburger--collapse.is-active .hamburger-inner {
            transform: translate3d(0, -10px, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            background-color: #FFF;
        }
        .hamburger--collapse.is-active .hamburger-inner::after {
            top: 0;
            opacity: 0;
            transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
            background-color: #FFF;
        }
        .hamburger--collapse.is-active .hamburger-inner::before {
            top: 0;
            transform: rotate(-90deg);
            transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            background-color: #FFF;
        }

        nav {
            position: absolute;
            visibility: hidden;
            width: 100%;
            height: 0px;
            background: $base-color1;
            top: -45px;
            left: 0px;
            z-index: 9;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;

            @include breakpoint(tablet-landscape) {
                overflow-y: scroll;
            }

            ul {
                visibility: hidden;
                opacity: 0;
                float: right;
                width: 100%;
                max-width: 600px;
                padding: 150px 0px 15px 0px;
                margin: 0px;
                list-style: none;
                -webkit-transition: all 0s 0s;
                transition: all 0s 0s;

                li {
                    float: left;
                    width: 100%;
                    padding: 10px 0px;
                    border-bottom: 1px solid $base-color2;

                    @include breakpoint(laptop) {
                        padding: 8px 0px;
                    }

                    &.active {
                        a {
                            color: $base-color2;
                        }
                    }

                    a {
                        float: left;
                        font-family: 'accordalternatebold';
                        color: #FFF;
                        font-size: 20px;
                        text-decoration: none;
                        -webkit-transition: opacity 0.2s ease;
                        transition: opacity 0.2s ease;

                        &:hover {
                            opacity: 0.7;
                        }

                        @include breakpoint(laptop) {
                            font-size: 18px;
                        }
                    }

                    ul {
                        float: left;
                        padding: 10px 0px 0px 30px;

                        li {
                            border: 0px;

                            a {
                                font-family: 'accordalternateregular';
                                color: #FFF !important;
                            }

                            &.active {
                                a {
                                    color: $base-color2 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.header-container {
    position: relative;
    margin-top: 145px;
    height: 266px !important;

    @include breakpoint(laptop) {
        margin-top: 135px;
    }

    @include breakpoint(mobile) {
        margin-top: 115px;
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 127px;
        background: url('../img/header-mask.svg') center;
        background-size: 1920px 127px;
        bottom: 0px;
        left: 0px;
        z-index: 2;
    }

    &.dashboard-header {
        height: -webkit-calc(100% - 280px) !important;
        height: calc(100% - 280px) !important;

        @include breakpoint(laptop) {
            height: -webkit-calc(100% - 230px) !important;
            height: calc(100% - 230px) !important;
        }

        h1 {
            font-family: accordalternatebold;
            color: #FFF;
            font-size: 80px;
            line-height: 84px;
            padding: 0px;
            margin: 0px;

            @include breakpoint(laptop) {
                font-size: 60px;
                line-height: 64px;
            }

            @include breakpoint(tablet-landscape) {
                font-size: 48px;
                line-height: 52px;
            }

            @include breakpoint(mobile) {
                font-size: 40px;
                line-height: 44px;
            }
        }

        @include breakpoint(mobile) {
            max-height: 350px;
        }

        .background {
            background-position: top;
        }
    }

    .container {
        height: 100%;

        .header-content {
            height: auto;
            width: -webkit-calc(100% - 30px);
            width: calc(100% - 30px);
            top: 50%;
            transform: translateY(-20%);
            -webkit-transform: translateY(-20%);
            text-align: left;
            z-index: 5;

            .button {
                position: relative;
                font-family: 'Fira Sans', sans-serif;
                font-weight: 700;
                font-style: normal;
                background: $base-color4;
                color: #FFF;
                font-size: 24px;
                padding: 20px 65px 20px 20px;
                text-decoration: none;
                -webkit-border-radius: 5px;
                border-radius: 5px;

                &:hover {
                    background-color: #f78c17;
                }

                @include breakpoint(tablet-landscape) {
                    font-size: 21px;
                    padding: 15px 50px 15px 15px;
                }

                @include breakpoint(mobile) {
                    font-size: 18px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    background: url('../img/btn.svg') no-repeat;
                    background-size: 34px 34px;
                    top: 13px;
                    right: 15px;
                    -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);

                    @include breakpoint(tablet-landscape) {
                        top: 11px;
                        width: 32px;
                        height: 32px;
                        background-size: 32px 32px;
                    }
                }
            }
        }
    }
}

.lead {
    font-size: 21px;
    line-height: 28px;

    @include breakpoint(tablet-landscape) {
        font-size: 18px;
        line-height: 26px;
    }

    .quickfinders {
        padding-bottom: 30px;

        .row {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            @include breakpoint(mobile) {
                display: block;
            }

            .quickfinder {

                &:nth-child(2) {
                    .inner {
                        margin-left: -10px;
                        -webkit-border-radius: 0px;
                        border-radius: 0px;
                        border-left: 1px #FFF dashed;

                        @include breakpoint(mobile) {
                            -webkit-border-radius: 0px;
                            border-radius: 0px;
                            margin: 0px;
                            border: 0px;
                            border-bottom: 1px #FFF dashed;
                        }
                    }
                }

                &:nth-child(3) {
                    .inner {
                        margin-left: -20px;
                        -webkit-border-radius: 0px 5px 5px 0px;
                        border-radius: 0px 5px 5px 0px;
                        border-left: 1px #FFF dashed;

                        @include breakpoint(mobile) {
                            -webkit-border-radius: 0px;
                            border-radius: 0px;
                            margin: 0px;
                            border: 0px;
                        }
                    }
                }

                .inner {
                    float: left;
                    width: -webkit-calc(100% + 20px);
                    width: calc(100% + 20px);
                    height: 100%;
                    padding: 0px;
                    background: $base-color2;
                    color: #FFF;
                    -webkit-border-radius: 5px 0px 0px 5px;
                    border-radius: 5px 0px 0px 5px;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;

                    &:hover {
                        background: #91ce34;
                    }

                    @include breakpoint(mobile) {
                        width: 100%;
                        -webkit-border-radius: 0px;
                        border-radius: 0px;
                        margin: 0px;
                        border-bottom: 1px #FFF dashed;
                    }

                    p {
                        float: left;
                        width: 100%;
                        padding: 0px;
                        margin: 0px;

                        a {
                            float: left;
                            position: relative;
                            width: 100%;
                            padding: 30px 60px 30px 30px;
                            font-family: 'Fira Sans', sans-serif;
                            font-weight: 700;
                            font-style: normal;
                            font-size: 21px;
                            color: #FFF;
                            text-decoration: none;

                            @include breakpoint(mobile) {
                                font-size: 18px;
                            }

                            &:before {
                                content: "";
                                position: absolute;
                                width: 36px;
                                height: 36px;
                                background: url('../img/btn.svg') no-repeat;
                                background-size: 36px 36px;
                                top: 50%;
                                right: 20px;
                                -webkit-transform: translateY(-50%) rotate(-90deg);
                                transform: translateY(-50%) rotate(-90deg);
                            }
                        }
                    }

                }
            }
        }
    }
}

.newsitems {
    background: $base-color3;
}

.articles {
    article {
        float: left;
        width: 100%;
        height:100%;


        header {
            float: left;
            width: 100%;

            time {
                float: left;
                clear: left;
                padding-top: 10px;
                font-size: 16px;
            }

            figure {
                float: left;
                width: 100%;
                padding: 0px;
                margin: 0px;

                img {
                    float: left;
                    width: 100%;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                }

                a {
                    img {
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

        }

        main {
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 20px;

            h2 {
                float: left;
                width: 100%;
                padding: 15px 0px;
                margin: 0px 0px 10px 0px;
                font-size: 28px;
                line-height: 32px;

                @include breakpoint(mobile) {
                    font-size: 24px;
                    line-height: 28px;
                }

                a {
                    text-decoration: none;
                    color: $base-color1;
                }
            }
        }

    }

    // Flex row
    .row.flex-row {
        &:before,&:after {display: none;}
    }
}

.read-more {
    .quickfinders {
        .row {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            @include breakpoint(mobile) {
                display: block;
            }

            .quickfinder {

                &:nth-child(2) {
                    .inner {
                        margin-left: -10px;
                        -webkit-border-radius: 0px;
                        border-radius: 0px;
                        border-left: 1px #FFF dashed;

                        @include breakpoint(mobile) {
                            -webkit-border-radius: 0px;
                            border-radius: 0px;
                            border: 0px;
                            border-bottom: 1px #FFF dashed;
                            margin: 0px;
                        }
                    }
                }

                &:nth-child(3) {
                    .inner {
                        margin-left: -20px;
                        -webkit-border-radius: 0px 10px 10px 0px;
                        border-radius: 0px 10px 10px 0px;
                        border-left: 1px #FFF dashed;

                        @include breakpoint(mobile) {
                            -webkit-border-radius: 0px;
                            border-radius: 0px;
                            border: 0px;
                            margin: 0px;
                        }
                    }
                }

                .inner {
                    float: left;
                    position: relative;
                    width: -webkit-calc(100% + 20px);
                    width: calc(100% + 20px);
                    height: 100%;
                    padding: 30px;
                    background: $base-color4;
                    color: #FFF;
                    -webkit-border-radius: 10px 0px 0px 10px;
                    border-radius: 10px 0px 0px 10px;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;

                    &:hover {
                        background-color: #f78c17;
                    }

                    @include breakpoint(mobile) {
                        width: 100%;
                        -webkit-border-radius: 0px;
                        border-radius: 0px;
                        border-bottom: 1px #FFF dashed;
                    }

                    &._clickable {
                        cursor: pointer;
                    }

                    h2 {
                        font-size: 28px;
                        line-height: 32px;
                        color: #FFF;

                        @include breakpoint(tablet) {
                            font-size: 24px;
                            line-height: 28px;
                            padding-right: 60px;
                        }
                    }

                    p {
                        float: left;
                        width: 100%;
                        padding: 0px 0px 15px 0px;
                        margin: 0px;

                        @include breakpoint(mobile) {
                            padding-right: 60px;
                            padding-bottom: 0px;
                        }

                        .button {
                            width: 70px;
                            height: 70px;
                            background: url('../img/btn.svg');
                            background-size: 70px 70px;
                            -webkit-transform: rotate(-90deg);
                            transform: rotate(-90deg);
                            overflow: hidden;
                            text-indent: -999px;

                            @include breakpoint(mobile) {
                                position: absolute;
                                top: 50%;
                                right: 20px;
                                width: 60px;
                                height: 60px;
                                background-size: 60px 60px;
                                -webkit-transform: translateY(-50%) rotate(-90deg);
                                transform: translateY(-50%) rotate(-90deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

.page {

    section {
        padding: 0px;
        margin: 0px;

        &.tips {
            background: #fef8f2;
            margin-top: 30px;
            margin-bottom: 30px;
            padding-top: 30px;
            padding-bottom: 50px;
        }

        &:first-child {
            padding-top: 50px;
        }

        &:last-child {
            padding-bottom: 50px;
        }

        h1 {
            font-size: 32px;
            padding: 0px 0px 15px 0px;
            margin: 0px;

            @include breakpoint(mobile) {
                font-size: 24px;
                line-height: 28px;
            }
        }

        h2 {
            font-size: 21px;
            color: $base-color4;
            padding: 15px 0px 0px 0px;
            margin: 0px;
        }

        p {
            padding: 0px 0px 15px 0px;
            margin: 0px;
        }

        .lead {
            p {
                font-size: 21px;

                @include breakpoint(mobile) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }

        ol {
            max-width: 950px;

            li {
                padding-bottom: 20px;
            }
        }

        .collapse {

            h2 {
                float: left;
                width: 100%;
                padding: 12px 15px;
                border: 1px solid $base-color4;
                background: #fef1e3;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                margin-bottom: 10px;
                font-size: 21px;
                cursor: pointer;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;

                &:hover {
                    background: $base-color4;
                    color: #FFF;
                }
            }

            .page-row-blocks {
                display: none;

                .inner {
                    padding: 15px 0px;

                    ul {
                        padding: 0px 15px;
                        margin: 0px;
                    }
                }
            }
        }

        &.experiment {
            padding-top: 40px;

            h2 {
                color: $base-color1;
                font-size: 32px;
                line-height: 36px;
                padding: 0px 0px 15px 0px;
                margin: 0px;
            }

            img {
                margin: 30px 0px 20px 0px;
                width: 100%;
                height: auto;
            }
        }

        &.case {
            border-top: 1px solid #469bc4;
            padding: 30px 0px;

            &:nth-child(1) {
                margin-top: 50px;
            }

            img {
                width: 100%;
                max-width: 260px;
                height: auto;

                @include breakpoint(mobile) {
                    -webkit-transform: scale(1.2);
                    transform: scale(1.2);
                }
            }

            h2 {
                color: $base-color1;
                font-size: 28px;
                padding: 0px 0px 5px 0px;
                margin: 0px;

                @include breakpoint(mobile) {
                    font-size: 21px;
                }
            }

            h4 {
                color: $base-color4;
                padding: 0px 0px 10px 0px;
                margin: 0px;
            }

            em {
                color: $base-color2;
            }
        }

        .faq {
            h2 {
                position: relative;
                font-size: 24px;
                color: $base-color2;
                padding-bottom: 20px;
                padding-left: 25px;
                cursor: pointer;

                &:before {
                    content: "»";
                    position: absolute;
                    top: 14px;
                    left: 0px;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                }

                &.expanded {
                    &:before {
                        content: "»";
                        position: absolute;
                        top: 16px;
                        left: 0px;
                        -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                    }
                }
            }

            .questions {
                display: none;

                .question {
                    margin-bottom: 10px;

                    h3 {
                        font-size: 18px;
                        border: 1px solid $base-color2;
                        padding: 10px;
                        margin: 0px;
                        -webkit-border-radius: 10px;
                        border-radius: 10px;
                        cursor: pointer;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;

                        &:hover {
                            background-color: $base-color2;
                            color: #FFF;
                        }
                    }

                    .answer {
                        display: none;
                        padding: 20px 0px 0px 0px;

                        p {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        &.search-results {

            h2 {
                padding-bottom: 15px;
            }

            .search-result {
                float: left;
                clear: left;
                position: relative;
                padding-bottom: 2px;
                padding-left: 25px;

                &:before {
                    content: "»";
                }
            }

        }

        &.single-article {
            article {
                header {
                    float: left;
                    width: 100%;

                    figure {
                        float: left;
                        padding: 0px;
                        margin: 0px;

                        img {
                            float: left;
                            width: auto;
                            max-width: 100%;
                            padding-bottom: 10px;
                        }
                    }

                    time {
                        float: left;
                        clear: left;
                        padding-bottom: 10px;
                    }
                }
            }
        }

        &.communities {

            .collection {
                float: left;
                width: -webkit-calc(100% + 10px);
                width: calc(100% + 10px);
                padding-top: 30px;
                margin-left: -5px;
            }

            .col {
                padding: 0px;
                padding: 5px;
            }

            img {
                float: left;
                width: 100%;
                border: 1px solid $base-color1;
            }
        }

        iframe {
            border: 0px;
            width: 100%;
            max-width: 600px;
            height: 1600px;

            @include breakpoint(mobile) {
                height: 1800px;
            }
        }
    }
}

form {
    width: 100%;
    max-width: 800px;

    .radio-group {
        .input-group {
            float: left;
            width: 100%;
        }
    }

    input {
        &[type=text], &[type=email], &[type=number], &[type=tel] {
            padding: 12px 10px;
            border: 1px solid #80ba26;
            -webkit-border-radius: 8px;
            border-radius: 8px;
            font-size: 16px;
        }
    }

    select {
        border: 1px solid #80ba26 !important;
        -webkit-border-radius: 8px !important;
        border-radius: 8px !important;
    }

    button, .button {
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #f07d00;
        color: #FFF;
        font-weight: bold;
        font-size: 18px;
        margin-top: 10px;
        -webkit-border-radius: 8px;
        border-radius: 8px;
        cursor: pointer;
        border: 0px;
    }
}


#mc_embed_signup {
    #mc-embedded-subscribe-form {
        padding: 0px;
        width: 100%;
        max-width: 800px;

        fieldset {
            border: 0px;
            padding: 0px;

            span.description {
                float: left;
                width: calc(100% - 30px);
            }
        }

        .mc-field-group {
            padding-bottom: 8px;
            width: auto;

            input {
                &[type=text], &[type=email] {
                    padding: 12px 10px;
                    border: 1px solid #80ba26;
                    -webkit-border-radius: 8px;
                    border-radius: 8px;
                    font-size: 16px;
                }

                &[type=checkbox] {
                    float: left;
                    width: auto;
                    margin-top: 7px;
                    margin-right: 10px;
                }
            }
        }

        .mc-field-group {

            input[type=radio] {
                margin-right: 10px;
            }
        }

        .button {
            width: 100%;
            height: 50px;
            line-height: 50px;
            background: #f07d00;
            color: #FFF;
            font-weight: bold;
            font-size: 18px; 
            margin-top: 10px;
            -webkit-border-radius: 8px;
            border-radius: 8px;
        }
    }
}

footer {
    position: relative;
    width: 100%;
    min-height: 400px;
    padding: 50px 0px 0px 0px;
    background: $base-color2;
    color: #FFF;

    @include breakpoint(laptop) {
        min-height: 450px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 120px;
    }

    &:before {
        content: "";
        position: absolute;
        background: url('../img/logo-footer.png');
        background-size: 500px 331px;
        width: 500px;
        height: 331px;
        bottom: 0px;
        right: 0px;

        @include breakpoint(laptop) {
            background-size: 500px 331px;
            width: 500px;
            height: 331px;
        }

        @include breakpoint(tablet) {
            background-size: 400px 265px;
            width: 400px;
            height: 265px;
        }

        @include breakpoint(mobile) {
            background-size: 226px 150px;
            width: 226px;
            height: 150px;
        }
    }

    .col {
        padding-bottom: 30px;
    }

    h2 {
        color: #FFF;
        font-size: 24px;
        line-height: 28px;
        padding: 0px 0px 5px 0px;
        margin: 0px;
    }

    p {
        padding: 0px;
        margin: 0px;
        font-size: 16px;
    }

    address {
        font-style: normal;

        a {
            color: #FFF;
            text-decoration: none;
        }
    }

    .social {
        list-style: none;
        padding: 0px;
        margin: 30px 0px 0px 0px;

        li {
            float: left;
            margin-right: 5px;

            a {
                float: left;
                width: 50px;
                height: 50px;
                background-size: 60px 60px;
                background-position: center;

                &.facebook {
                    background-image: url('../img/facebook.svg');
                }

                &.twitter {
                    background-image: url('../img/twitter.svg');
                }

                &:hover {
                    opacity: 0.95;
                }

            }
        }
    }

    // Footer btn
    .footer-btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #46606c;
        color: #FFF;
        font-weight: bold;
        font-size: 18px;
        margin-top: 10px;
        -webkit-border-radius: 8px;
        border-radius: 8px;

        clear: both;
        border: 0 none;
        transition: all 0.23s ease-in-out 0s;
        cursor: pointer;
        display: inline-block;
        padding: 0 22px;
        text-align: center;
        text-decoration: none;
        vertical-align: top;
        white-space: nowrap;
        max-width: 270px;
    }
}

.flex-row{
    @include flexbox();
    @include flex-wrap(wrap);


    article{
        margin-bottom:30px;
        main{
            height:auto;


            .news-footer{
            }

        }

    }
}

nav.pagination-nav {
    display: inline-block;
    float: left;

    // List
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        // List item
        li {
            display: inline-block;
            float: left;
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0;
            }

            // Link
            a {
                width: 35px;
                height: 35px;
                background-color: $base-color1;
                display: inline-block;
                float: left;
                color: #fff;
                @include transition(all .3s);
                text-decoration: none;
                padding: 0;
                line-height: 35px;
                text-align: center;
            }

            // Active/hover
            &.active a,&:hover a {
                background-color: $base-color2;
            }
        }
    }
}

.landing {
    position: absolute;
    width: 100%;
    min-height: 600px;
    height: -webkit-calc(100% - 145px);
    height: calc(100% - 145px);
    top: 145px;
    background: url('../img/landing.jpg') top center;
    background-size: cover;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(43,65,76,0.5);
        z-index: 3;
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 127px;
        background: url("../img/header-mask.svg") center;
        background-size: auto;
        background-size: 1920px 127px;
        bottom: 0px;
        left: 0px;
        z-index: 2;
    }

    .quickfinders {
        position: relative;
        padding-bottom: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 4;

        .row {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            @include breakpoint(mobile) {
                display: block;
            }

            .quickfinder {

                &:nth-child(1) {
                    .inner {
                        width: -webkit-calc(100% + 21px);
                        width: calc(100% + 21px);

                        @include breakpoint(mobile) {
                            width: 100%;
                        }
                    }
                }

                &:nth-child(2) {
                    .inner {
                        margin-left: -10px;
                        width: -webkit-calc(100% + 21px);
                        width: calc(100% + 21px);
                        -webkit-border-radius: 0px;
                        border-radius: 0px;
                        border-left: 1px #FFF dashed;

                        @include breakpoint(mobile) {
                            -webkit-border-radius: 0px;
                            border-radius: 0px;
                            width: 100%;
                            margin: 0px;
                            border: 0px;
                            border-bottom: 1px #FFF dashed;
                        }
                    }
                }

                &:nth-child(3) {
                    .inner {
                        margin-left: -20px;
                        -webkit-border-radius: 0px 5px 5px 0px;
                        border-radius: 0px 5px 5px 0px;
                        border-left: 1px #FFF dashed;

                        @include breakpoint(mobile) {
                            -webkit-border-radius: 0px;
                            border-radius: 0px;
                            margin: 0px;
                            border: 0px;
                        }
                    }
                }

                .inner {
                    float: left;
                    width: -webkit-calc(100% + 20px);
                    width: calc(100% + 20px);
                    height: 100%;
                    padding: 0px;
                    background: $base-color2;
                    color: #FFF;
                    -webkit-border-radius: 5px 0px 0px 5px;
                    border-radius: 5px 0px 0px 5px;
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;

                    &:hover {
                        background: #91ce34;
                    }

                    @include breakpoint(mobile) {
                        width: 100%;
                        -webkit-border-radius: 0px;
                        border-radius: 0px;
                        margin: 0px;
                        border-bottom: 1px #FFF dashed;
                    }

                    p {
                        float: left;
                        width: 100%;
                        padding: 0px;
                        margin: 0px;

                        a {
                            float: left;
                            position: relative;
                            width: 100%;
                            padding: 30px 60px 30px 30px;
                            font-family: 'Fira Sans', sans-serif;
                            font-weight: 700;
                            font-style: normal;
                            font-size: 21px;
                            color: #FFF;
                            text-decoration: none;

                            @include breakpoint(tablet) {
                                font-size: 18px;
                            }

                            &:before {
                                content: "";
                                position: absolute;
                                width: 36px;
                                height: 36px;
                                background: url('../img/btn.svg') no-repeat;
                                background-size: 36px 36px;
                                top: 50%;
                                right: 20px;
                                -webkit-transform: translateY(-50%) rotate(-90deg);
                                transform: translateY(-50%) rotate(-90deg);
                            }
                        }
                    }

                }
            }
        }

        .newsletter {
            padding-top: 30px;

            .inner {
                float: left;
                font-family: 'Fira Sans', sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 21px;
                width: 100%;
                background: #f07d00;
                -webkit-border-radius: 5px;
                border-radius: 5px;
                padding: 25px 60px 25px 25px;
                color: #FFF;
                text-decoration: none;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;

                @include breakpoint(tablet) {
                    font-size: 18px;
                }

                &:hover {
                    background: #f38b1a;
                }

                &:before {
                    content: "";
                    position: absolute;
                    width: 36px;
                    height: 36px;
                    background: url("../img/btn.svg") no-repeat;
                    background-size: auto;
                    background-size: 36px 36px;
                    top: 50%;
                    right: 35px;
                    -webkit-transform: translateY(-50%) rotate(-90deg);
                    transform: translateY(-50%) rotate(-90deg);
                }
            }
        }
    }
}

.container_parent {
    display: none;
}