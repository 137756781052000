// Background
@mixin background($color, $image, $repeat, $horizontal, $vertical) {
  background-color: $color;
  background-image: $image;
  background-repeat: $repeat;
  background-position: $horizontal $vertical;
}

// Border
@mixin border ($width, $style, $color) {
  border-width: $width;
  border-style: $style;
  border-color: $color;
}