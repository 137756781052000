.button {
	width: auto;
	height: auto;
	border: none;
	background-color: #FFF;
	color: #000;
	transition: all .3s;
	outline: none;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}