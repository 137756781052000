// Table
table {
    width: 100%;
    border-collapse: collapse;


    // Table row
    tr {
        background-color: transparent;

        @include breakpoint(mobile) {
            width: 100%;
            display: inline-block;
            margin-bottom: 15px;
            border: none;

            &:last-of-type {
                border-bottom: none;
            }
        }

        // Table column
        td {
            padding: 15px 20px;

            @include breakpoint(mobile) {
                padding: 7px 15px;
                display: inline-block;
                width: 100%;
            }
        }
    }

    // Table head
    thead {

        // Table head column
        th {
            font-weight: 700;
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }
}